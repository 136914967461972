import { Group } from 'three';

class Knobs {
  knob;

  #burnerDial;
  #convectionOvenDial;
  #convectionOvenSwitch;
  #gasOvenDial;
  #inductionDial;
  #planchaDial;
  #warmingCupboardDial;

  #classiqueKnob;
  #moderneKnob;

  #activeKnobs = {
    farLeft: new Group(),
    left: new Group(),
    right: new Group(),
    farRight: new Group(),
  };
  #activeKnobDials = {
    farLeft: new Group(),
    left: new Group(),
    right: new Group(),
    farRight: new Group(),
  };
  #activeCPElectrical = {
    farLeft: new Group(),
    left: new Group(),
    right: new Group(),
    farRight: new Group(),
  };

  // The base 3D Sully model that the knobs will be added to
  #range;

  #color = 'burgundyRed';
  #line = 'classique';

  #materials;

  #farLeft = {
    optionBack: null,
    optionFront: null,
    cupboard: 'warming',
  };
  #left = {
    optionBack: null,
    optionFront: null,
    baseBurnerBack: null,
    baseBurnerCenter: null,
    baseBurnerFront: null,
    oven: 'gas-oven',
  };
  #right = {
    baseBurnerFront: 'burner',
    baseBurnerBack: 'burner',
    optionBack: null,
    optionFront: null,
    oven: 'convection-oven',
  };
  #farRight = { cupboard: 'warming', optionBack: null, optionFront: null };

  constructor(knobModels, range, materials) {
    // Knobs
    this.#classiqueKnob = knobModels.classiqueKnob;
    this.#moderneKnob = knobModels.moderneKnob;
    this.knob = this.#classiqueKnob;

    // Knob Dials
    this.#burnerDial = knobModels.knobBurnerDial;
    this.#convectionOvenDial = knobModels.convectionOvenDial;
    this.#gasOvenDial = knobModels.gasOvenDial;
    this.#inductionDial = knobModels.inductionDial;
    this.#planchaDial = knobModels.planchaDial;
    this.#warmingCupboardDial = knobModels.warmingCupboardDial;

    // Switches and Lights
    this.#convectionOvenSwitch = knobModels.convectionOvenSwitch;

    this.#range = range;
    this.#materials = materials;
    this.#initializeKnobGroups();
    this.#addBaseKnobs();
  }

  threeFeuxBaseTop() {
    this.#left.baseBurnerBack = null;
    this.#left.baseBurnerCenter = 'burner';
    this.#left.baseBurnerFront = null;

    this.#updateLeftKnobs();
  }

  fourFeuxBaseTop() {
    this.#left.baseBurnerBack = 'burner';
    this.#left.baseBurnerCenter = null;
    this.#left.baseBurnerFront = 'burner';

    this.#updateLeftKnobs();
  }

  add2Burners(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.optionBack = 'burner';
        this.#farLeft.optionFront = 'burner';
        this.#updateFarLeftKnobs();
        break;

      case 'left':
        this.#left.optionBack = 'burner';
        this.#left.optionFront = 'burner';
        this.#updateLeftKnobs();
        break;

      case 'right':
        this.#right.optionBack = 'burner';
        this.#right.optionFront = 'burner';
        this.#updateRightKnobs();
        break;

      case 'far-right':
        this.#farRight.optionBack = 'burner';
        this.#farRight.optionFront = 'burner';
        this.#updateFarRightKnobs();
        break;
    }
  }

  add1Burner(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.optionBack = null;
        this.#farLeft.optionFront = 'burner';
        this.#updateFarLeftKnobs();
        break;

      case 'right':
        this.#right.optionBack = null;
        this.#right.optionFront = 'burner';
        this.#updateRightKnobs();
        break;

      case 'far-right':
        this.#farRight.optionBack = null;
        this.#farRight.optionFront = 'burner';
        this.#updateFarRightKnobs();
        break;
    }
  }

  addFlameGrill(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.optionBack = null;
        this.#farLeft.optionFront = 'flame-grill';
        this.#updateFarLeftKnobs();
        break;

      case 'far-right':
        this.#farRight.optionBack = null;
        this.#farRight.optionFront = 'flame-grill';
        this.#updateFarRightKnobs();
        break;
    }
  }

  addPlancha(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.optionBack = null;
        this.#farLeft.optionFront = 'plancha';
        this.#updateFarLeftKnobs();
        break;

      case 'right':
        this.#right.optionBack = null;
        this.#right.optionFront = 'plancha';
        this.#updateRightKnobs();
        break;

      case 'far-right':
        this.#farRight.optionBack = null;
        this.#farRight.optionFront = 'plancha';
        this.#updateFarRightKnobs();
        break;
    }
  }

  addMultiCooker(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.optionBack = null;
        this.#farLeft.optionFront = 'multi-cooker';
        this.#updateFarLeftKnobs();
        break;

      case 'far-right':
        this.#farRight.optionBack = null;
        this.#farRight.optionFront = 'multi-cooker';
        this.#updateFarRightKnobs();
        break;
    }
  }

  addInductionRings(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.optionBack = 'induction-ring';
        this.#farLeft.optionFront = 'induction-ring';
        this.#updateFarLeftKnobs();
        break;

      case 'right':
        this.#right.optionBack = 'induction-ring';
        this.#right.optionFront = 'induction-ring';
        this.#updateRightKnobs();
        break;

      case 'far-right':
        this.#farRight.optionBack = 'induction-ring';
        this.#farRight.optionFront = 'induction-ring';
        this.#updateFarRightKnobs();
        break;
    }
  }

  addSSWorkstation(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.optionBack = null;
        this.#farLeft.optionFront = null;
        this.#updateFarLeftKnobs();
        break;

      case 'left':
        this.#left.optionBack = null;
        this.#left.optionFront = null;
        this.#updateLeftKnobs();
        break;

      case 'right':
        this.#right.optionBack = null;
        this.#right.optionFront = null;
        this.#updateRightKnobs();
        break;

      case 'far-right':
        this.#farRight.optionBack = null;
        this.#farRight.optionFront = null;
        this.#updateFarRightKnobs();
        break;
    }
  }

  selectWarmingCupboard(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.cupboard = 'warming';
        this.#updateFarLeftKnobs();
        break;

      case 'far-right':
        this.#farRight.cupboard = 'warming';
        this.#updateFarRightKnobs();
        break;
    }
  }

  selectStorageCupboard(group) {
    switch (group) {
      case 'far-left':
        this.#farLeft.cupboard = null;
        this.#updateFarLeftKnobs();
        break;

      case 'far-right':
        this.#farRight.cupboard = null;
        this.#updateFarRightKnobs();
        break;
    }
  }

  changeLine(line) {
    this.#line = line;

    if (line === 'classique') {
      this.knob = this.#classiqueKnob;
    } else if (line === 'moderne') {
      this.knob = this.#moderneKnob;
    }

    this.changeKnobDialColor(this.#color);
    this.#updateFarLeftKnobs();
    this.#updateLeftKnobs();
    this.#updateRightKnobs();
    this.#updateFarRightKnobs();
  }

  changeTrim(trim) {
    const knobGroups = [
      this.#activeKnobs.farLeft.name,
      this.#activeKnobs.left.name,
      this.#activeKnobs.right.name,
      this.#activeKnobs.farRight.name,
    ];

    knobGroups.forEach((group) => {
      const knobGroup = this.#range.getObjectByName(group);
      knobGroup.children.forEach((knob) => {
        const knobMetalPart = this.#knobMetalPart(knob);
        this.#materials.changeTrim(trim, [knobMetalPart]);
      });
    });

    this.#changeBaseKnobsTrim(trim);
  }

  changeKnobDialColor(color) {
    this.#color = color;

    const dialGroups = [
      this.#activeKnobDials.farLeft.name,
      this.#activeKnobDials.left.name,
      this.#activeKnobDials.right.name,
      this.#activeKnobDials.farRight.name,
    ];

    dialGroups.forEach((section) => {
      const rangeSection = this.#range.getObjectByName(section);
      rangeSection.children.forEach((dialGroup) => {
        this.#materials.changeKnobDialColor(color, this.#line, [
          ...dialGroup.children,
        ]);
      });
    });

    this.#materials.changeKnobDialColor(color, this.#line, [
      ...this.#burnerDial.children,
      ...this.#convectionOvenDial.children,
      ...this.#gasOvenDial.children,
      ...this.#inductionDial.children,
      ...this.#planchaDial.children,
      ...this.#warmingCupboardDial.children,
    ]);
  }

  #updateFarLeftKnobs() {
    const knobGroup = this.#range.getObjectByName(
      this.#activeKnobs.farLeft.name
    );
    knobGroup.remove(...knobGroup.children);

    const dialGroup = this.#range.getObjectByName(
      this.#activeKnobDials.farLeft.name
    );
    dialGroup.remove(...dialGroup.children);

    const knobsNeeded = Object.keys(this.#farLeft)
      .filter((key) => this.#farLeft[key])
      .reverse();

    const xPosOffset = 0.19516 - (3 - knobsNeeded.length) * 0.05;

    knobsNeeded.forEach((key, index) => {
      const knob = this.knob.clone();
      knob.position.x += -0.1 * index + xPosOffset;
      knobGroup.add(knob);

      switch (this.#farLeft[key]) {
        case 'burner':
          const burnerDial = this.#burnerDial.clone();
          burnerDial.position.x += -0.1 * index + xPosOffset;
          dialGroup.add(burnerDial);
          break;

        case 'warming':
          const warmingCupboardDial = this.#warmingCupboardDial.clone();
          warmingCupboardDial.position.x += -0.1 * index + xPosOffset;
          dialGroup.add(warmingCupboardDial);
          break;

        case 'flame-grill':
          const flameGrillDial = this.#burnerDial.clone();
          flameGrillDial.position.x += -0.1 * index + xPosOffset;
          dialGroup.add(flameGrillDial);
          break;

        case 'plancha':
          const planchaDial = this.#planchaDial.clone();
          planchaDial.position.x += -0.1 * index + xPosOffset;
          dialGroup.add(planchaDial);
          break;

        case 'multi-cooker':
          // TODO: Add multi-cooker dial markings
          break;

        case 'induction-ring':
          const inductionDial = this.#inductionDial.clone();
          inductionDial.position.x += -0.1 * index + xPosOffset;
          dialGroup.add(inductionDial);
          break;
      }
    });
  }

  #updateLeftKnobs() {
    const knobGroup = this.#range.getObjectByName(this.#activeKnobs.left.name);
    knobGroup.remove(...knobGroup.children);

    const dialGroup = this.#range.getObjectByName(
      this.#activeKnobDials.left.name
    );
    dialGroup.remove(...dialGroup.children);

    const knobsNeeded = Object.keys(this.#left)
      .filter((key) => this.#left[key])
      .reverse();

    const xPosOffset = 0.85016 - (5 - knobsNeeded.length) * 0.055;

    knobsNeeded.forEach((key, index) => {
      const knob = this.knob.clone();
      knob.position.x += -0.11 * index + xPosOffset;
      knobGroup.add(knob);

      switch (this.#left[key]) {
        case 'burner':
          const knobBurnerDial = this.#burnerDial.clone();
          knobBurnerDial.position.x += -0.11 * index + xPosOffset;
          dialGroup.add(knobBurnerDial);
          break;

        case 'gas-oven':
          const gasOvenDial = this.#gasOvenDial.clone();
          gasOvenDial.position.x += -0.11 * index + xPosOffset;
          dialGroup.add(gasOvenDial);
          break;

        case 'induction-ring':
          const inductionDial = this.#inductionDial.clone();
          inductionDial.position.x += -0.11 * index + xPosOffset;
          dialGroup.add(inductionDial);
          break;
      }
    });
  }

  #updateRightKnobs() {
    const knobGroup = this.#range.getObjectByName(this.#activeKnobs.right.name);
    knobGroup.remove(...knobGroup.children);

    const dialGroup = this.#range.getObjectByName(
      this.#activeKnobDials.right.name
    );
    dialGroup.remove(...dialGroup.children);

    const electricalGroup = this.#range.getObjectByName(
      this.#activeCPElectrical.right.name
    );
    electricalGroup.remove(...electricalGroup.children);

    const knobsNeeded = Object.keys(this.#right).filter(
      (key) => this.#right[key]
    );

    const xPosOffset = 1.07016 + (5 - knobsNeeded.length) * 0.055;

    knobsNeeded.forEach((key, index) => {
      const knob = this.knob.clone();
      knob.position.x += 0.11 * index + xPosOffset;
      knobGroup.add(knob);

      switch (this.#right[key]) {
        case 'burner':
          const burnerDial = this.#burnerDial.clone();
          burnerDial.position.x += 0.11 * index + xPosOffset;
          dialGroup.add(burnerDial);
          break;

        case 'convection-oven':
          const convectionOvenDial = this.#convectionOvenDial.clone();
          convectionOvenDial.position.x += 0.11 * index + xPosOffset;
          dialGroup.add(convectionOvenDial);

          const convectionOvenSwitch = this.#convectionOvenSwitch.clone();
          convectionOvenSwitch.position.x += 0.11 * index + xPosOffset;
          electricalGroup.add(convectionOvenSwitch);
          break;

        case 'plancha':
          const planchaDial = this.#planchaDial.clone();
          planchaDial.position.x += 0.11 * index + xPosOffset;
          dialGroup.add(planchaDial);
          break;

        default:
          break;
      }
    });
  }

  #updateFarRightKnobs() {
    const knobGroup = this.#range.getObjectByName(
      this.#activeKnobs.farRight.name
    );
    knobGroup.remove(...knobGroup.children);

    const dialGroup = this.#range.getObjectByName(
      this.#activeKnobDials.farRight.name
    );
    dialGroup.remove(...dialGroup.children);

    const knobsNeeded = Object.keys(this.#farRight).filter(
      (key) => this.#farRight[key]
    );

    const xPosOffset = 1.73016 + (3 - knobsNeeded.length) * 0.05;

    knobsNeeded.forEach((key, index) => {
      const knob = this.knob.clone();
      knob.position.x += 0.1 * index + xPosOffset;
      knobGroup.add(knob);

      switch (this.#farRight[key]) {
        case 'burner':
          const burnerDial = this.#burnerDial.clone();
          burnerDial.position.x += 0.1 * index + xPosOffset;
          dialGroup.add(burnerDial);
          break;

        case 'warming':
          const warmingCupboardDial = this.#warmingCupboardDial.clone();
          warmingCupboardDial.position.x += 0.1 * index + xPosOffset;
          dialGroup.add(warmingCupboardDial);
          break;

        case 'flame-grill':
          const flameGrillDial = this.#burnerDial.clone();
          flameGrillDial.position.x += 0.1 * index + xPosOffset;
          dialGroup.add(flameGrillDial);
          break;

        case 'plancha':
          const planchaDial = this.#planchaDial.clone();
          planchaDial.position.x += 0.1 * index + xPosOffset;
          dialGroup.add(planchaDial);
          break;

        case 'multi-cooker':
          // TODO: Add multi-cooker dial markings
          break;

        case 'induction-ring':
          const inductionDial = this.#inductionDial.clone();
          inductionDial.position.x += 0.1 * index + xPosOffset;
          dialGroup.add(inductionDial);
          break;
      }
    });
  }

  #knobMetalPart(knob) {
    if (this.#line === 'classique') {
      return knob.getObjectByName('C_LO2_knob_3');
    } else if (this.#line === 'moderne') {
      return knob.getObjectByName('M_LO2_knob_3');
    }
  }

  #changeBaseKnobsTrim(trim) {
    const classiqueMetal = this.#classiqueKnob.getObjectByName('C_LO2_knob_3');
    const moderneMetal = this.#moderneKnob.getObjectByName('M_LO2_knob_3');

    this.#materials.changeTrim(trim, [classiqueMetal, moderneMetal]);
  }

  #addBaseKnobs() {
    this.#updateFarLeftKnobs();
    this.#updateRightKnobs();
    this.#updateFarRightKnobs();
  }

  #initializeKnobGroups() {
    this.#activeKnobs.farLeft.name = 'far-left-knobs';
    this.#activeKnobs.left.name = 'left-knobs';
    this.#activeKnobs.right.name = 'right-knobs';
    this.#activeKnobs.farRight.name = 'far-right-knobs';

    this.#activeKnobDials.farLeft.name = 'far-left-knob-dials';
    this.#activeKnobDials.left.name = 'left-knob-dials';
    this.#activeKnobDials.right.name = 'right-knob-dials';
    this.#activeKnobDials.farRight.name = 'far-right-knob-dials';

    this.#activeCPElectrical.farLeft.name = 'far-left-electrical';
    this.#activeCPElectrical.left.name = 'left-electrical';
    this.#activeCPElectrical.right.name = 'right-electrical';
    this.#activeCPElectrical.farRight.name = 'far-right-electrical';

    this.#range.add(this.#activeKnobs.farLeft);
    this.#range.add(this.#activeKnobs.left);
    this.#range.add(this.#activeKnobs.right);
    this.#range.add(this.#activeKnobs.farRight);

    this.#range.add(this.#activeKnobDials.farLeft);
    this.#range.add(this.#activeKnobDials.left);
    this.#range.add(this.#activeKnobDials.right);
    this.#range.add(this.#activeKnobDials.farRight);

    this.#range.add(this.#activeCPElectrical.farLeft);
    this.#range.add(this.#activeCPElectrical.left);
    this.#range.add(this.#activeCPElectrical.right);
    this.#range.add(this.#activeCPElectrical.farRight);
  }
}

export { Knobs };
