import { Colors } from './Colors.js';
import { Metals } from './Metals.js';

class Materials {
  #colors;
  #metals;

  constructor(assets) {
    this.#colors = new Colors(assets);
    this.#metals = new Metals(assets);
  }

  changeTrim(trim, trimParts) {
    const trimMaterial = this.#metals[trim + 'Trim'] ?? this.#metals.brassTrim;

    trimParts.forEach((part) => (part.material = trimMaterial));
  }

  changeColor(color, colorParts) {
    const colorMaterial = this.#colors[color] ?? this.#colors.burgundyRed;

    colorParts.forEach((part) => (part.material = colorMaterial));
  }

  changeKnobDialColor(color, line, dials) {
    let colorMaterial;
    const brownDials = [
      'chantilly',
      'delftBlue',
      'englishCreme',
      'ivory',
      'mandarine',
      'provenceYellow',
      'roseQuartz',
      'tilleul',
      'vertSilice',
      'vertSologne',
      'white',
    ];

    if (line === 'moderne') {
      colorMaterial = this.#colors.blackBody;
    } else if (brownDials.includes(color)) {
      colorMaterial = this.#colors.brownDial;
    } else {
      colorMaterial = this.#colors.beigeDial;
    }

    dials.forEach((dial) => (dial.material = colorMaterial));
  }

  applyStainlessSteelTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.stainlessSteel));
  }

  applyGalvanizedSteelTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.galvanizedSteel));
  }

  applyBrassTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.brassTrim));
  }

  applyChromeTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.chromeTrim));
  }

  applyBrushedSSTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.brushedSSTrim));
  }

  applyEnamelCastIronTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#metals.enamelCastIron));
  }

  applyMatteBlackTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.matteBlack));
  }

  applyBlackBodyTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.blackBody));
  }

  applyBurgundyRedTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.burgundyRed));
  }

  applyWhiteTexture(...meshes) {
    meshes.forEach((mesh) => (mesh.material = this.#colors.white));
  }

  applyBurnerTextures(burners) {
    burners.forEach((burner) => {
      burner.cap.material = this.#metals.brassCap;
      burner.bowl.material = this.#metals.castAluminum;
      burner.blackRing.material = this.#colors.blackBody;
    });
  }

  applyThermocoupleTextures(thermocouple) {
    thermocouple.inner.material = this.#metals.brassCap;
    thermocouple.outer.material = this.#colors.blackBody;
  }

  applyIgnitorTextures(ignitor) {
    ignitor.inner.material = this.#metals.brassCap;
  }

  applyBeigeDialTexture(dialGroup) {
    dialGroup.children.forEach((dial) => {
      dial.material = this.#colors.beigeDial;
    });
  }

  applyAmberLightTexture(light) {
    light.material = this.#colors.amberLight;
  }

  applyRedLightTexture(light) {
    light.material = this.#colors.redLight;
  }
}

export { Materials };
