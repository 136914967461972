import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

function createControls(camera, canvas) {
  const controls = new OrbitControls(camera, canvas);

  // controls.minDistance = 2;
  // controls.maxDistance = 15;
  // controls.maxPolarAngle = Math.PI / 2;
  // controls.minPolarAngle = Math.PI / 3;
  controls.enableDamping = true;
  controls.dampingFactor = 0.1;
  controls.enablePan = true;
  controls.autoRotate = false;
  // controls.autoRotate = true; // Toggle this if you'd like the chair to automatically rotate
  // controls.autoRotateSpeed = 5; // 30

  controls.tick = () => controls.update();

  return controls;
}

export { createControls };
