import { Group } from 'three';

function buildBurners(burnerParts, materials) {
  const fiveKParts = gather5KParts(burnerParts);
  const elevenKParts = gather11KParts(burnerParts);
  const fifteenKParts = gather15KParts(burnerParts);
  const eighteenKParts = gather18KParts(burnerParts);
  const thermocoupleParts = gatherThermocoupleParts(burnerParts);
  const ignitorParts = gatherIgnitorParts(burnerParts);

  materials.applyBurnerTextures([
    fiveKParts,
    elevenKParts,
    fifteenKParts,
    eighteenKParts,
  ]);

  materials.applyThermocoupleTextures(thermocoupleParts);
  materials.applyIgnitorTextures(ignitorParts);

  const thermocouple = thermocoupleGroup(thermocoupleParts);
  const ignitor = ignitorGroup(ignitorParts);

  return [
    fiveKBurner(fiveKParts, thermocouple, ignitor),
    elevenKBurner(elevenKParts, thermocouple, ignitor),
    fifteenKBurner(fifteenKParts, thermocouple, ignitor),
    eighteenKBurner(eighteenKParts, thermocouple, ignitor),
  ];
}

function fiveKBurner(fiveKParts, thermocoupleGroup, ignitorGroup) {
  const fiveKBurner = new Group();

  const thermocouple = thermocoupleGroup.clone();
  thermocouple.position.x += 0.0011;
  thermocouple.position.z += 0.0262;

  const ignitor = ignitorGroup.clone();
  ignitor.position.x -= 0.0011;
  ignitor.position.z += 0.026;

  const burnerParts = [
    fiveKParts.cap,
    fiveKParts.bowl,
    fiveKParts.blackRing,
    thermocouple,
    ignitor,
  ];

  burnerParts.forEach((mesh) => fiveKBurner.add(mesh));

  fiveKBurner.position.x += 0.352;
  fiveKBurner.position.z += 0.1435;

  return fiveKBurner;
}

function elevenKBurner(elevenKParts, thermocoupleGroup, ignitorGroup) {
  const elevenKBurner = new Group();

  const thermocouple = thermocoupleGroup.clone();
  thermocouple.position.x += 0.0011;
  thermocouple.position.z += 0.0177;

  const ignitor = ignitorGroup.clone();
  ignitor.position.x -= 0.0011;
  ignitor.position.z += 0.018;

  const burnerParts = [
    elevenKParts.cap,
    elevenKParts.bowl,
    elevenKParts.blackRing,
    thermocouple,
    ignitor,
  ];

  burnerParts.forEach((mesh) => elevenKBurner.add(mesh));

  elevenKBurner.position.x += 0.352;
  elevenKBurner.position.z += 0.1435;

  return elevenKBurner;
}

function fifteenKBurner(fifteenKParts, thermocoupleGroup, ignitorGroup) {
  const fifteenKBurner = new Group();

  const thermocouple = thermocoupleGroup.clone();
  thermocouple.position.z += 0.0085;

  const ignitor = ignitorGroup.clone();
  ignitor.position.z += 0.0085;

  const burnerParts = [
    fifteenKParts.cap,
    fifteenKParts.bowl,
    fifteenKParts.blackRing,
    thermocouple,
    ignitor,
  ];

  burnerParts.forEach((mesh) => fifteenKBurner.add(mesh));

  fifteenKBurner.position.x += 0.352;
  fifteenKBurner.position.z += 0.1435;

  return fifteenKBurner;
}

function eighteenKBurner(eighteenKParts, thermocoupleGroup, ignitorGroup) {
  const eighteenKBurner = new Group();

  const burnerParts = [
    eighteenKParts.cap,
    eighteenKParts.bowl,
    eighteenKParts.blackRing,
    thermocoupleGroup.clone(),
    ignitorGroup.clone(),
  ];

  burnerParts.forEach((mesh) => eighteenKBurner.add(mesh));

  return eighteenKBurner;
}

function gather5KParts(burnerParts) {
  return {
    cap: burnerParts.getObjectByName('5K_btu_Top_15kW'),
    bowl: burnerParts.getObjectByName('5K_btu_Bottom_15kW'),
    blackRing: burnerParts.getObjectByName('5K_btu_Top_BlackBody_15kW'),
  };
}

function gather11KParts(burnerParts) {
  return {
    cap: burnerParts.getObjectByName('11K_btu_Top_3kW'),
    bowl: burnerParts.getObjectByName('11K_btu_Bottom_3kW'),
    blackRing: burnerParts.getObjectByName('11K_btu_Top_BlackBody_3kW'),
  };
}

function gather15KParts(burnerParts) {
  return {
    cap: burnerParts.getObjectByName('15K_btu_Top_4kW'),
    bowl: burnerParts.getObjectByName('15K_btu_Bottom_4kW'),
    blackRing: burnerParts.getObjectByName('15K_btu_Top_BlackBody_4kW'),
  };
}

function gather18KParts(burnerParts) {
  return {
    cap: burnerParts.getObjectByName('18K_btu_Top_5kW'),
    bowl: burnerParts.getObjectByName('18K_btu_Bottom_5kW'),
    blackRing: burnerParts.getObjectByName('18K_btu_Top_BlackBody_5kW'),
  };
}

function gatherThermocoupleParts(burnerParts) {
  return {
    inner: burnerParts.getObjectByName('Burner_Thermocouple_InnerBody'),
    outer: burnerParts.getObjectByName('Burner_Thermocouple_OuterBody'),
  };
}

function gatherIgnitorParts(burnerParts) {
  return {
    inner: burnerParts.getObjectByName('Burner_Igniter_InnerBody'),
    outer: burnerParts.getObjectByName('Burner_Igniter_OuterBody'),
  };
}

function thermocoupleGroup(thermocoupleParts) {
  const thermocouple = new Group();

  thermocouple.add(
    thermocoupleParts.outer.clone(),
    thermocoupleParts.inner.clone()
  );

  return thermocouple;
}

function ignitorGroup(ignitorParts) {
  const ignitor = new Group();

  ignitor.add(ignitorParts.outer.clone(), ignitorParts.inner.clone());

  return ignitor;
}

export { buildBurners };
