import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { RepeatWrapping, TextureLoader } from 'three';

import backImage from '/assets/textures/body_back_texture_final.png?url';
import blackTextureImage from '/assets/textures/black-plain-concrete-textured.jpg?url';
import brassCapImage from '/assets/textures/texture_1.png?url';
import cherryRedImage from '/assets/textures/cherry-red-lacanche-colour-sample.jpg?url';
import nickelTextureImage from '/assets/textures/nickel-lacanche-sample.jpg?url';
import ssTextureImage from '/assets/textures/stainless-steel-lacanche-sample.jpg?url';

class AssetLoader {
  // GLB 3D models for range
  allBurnersData;
  burnerbaseData;
  classiqueKnobData;
  eighteenKBurnerGrateData;
  flameGrillData;
  frontGrillData;
  inductionRingsData;
  knobBurnerDialData;
  knobDialsData;
  moderneKnobData;
  moderneTrimData;
  multiCookerData;
  ovenSwitchData;
  planchaData;
  rangeData;
  traditionalPlateData;
  twoBurnerGrateData;

  // GLB 3D model for backsplash
  backsplashData;

  // GLB 3D model for hood
  hoodData;

  // Texture images
  backTexture;
  blackTexture;
  brassCapTexture;
  cherryRedTexture;
  nickelTexture;
  ssTexture;

  // Three.js loaders
  #gltfLoader;
  #textureLoader;

  constructor() {
    this.#gltfLoader = new GLTFLoader();
    this.#textureLoader = new TextureLoader();
  }

  async init() {
    [
      // GLB 3D models
      this.twoBurnerGrateData,
      this.eighteenKBurnerGrateData,
      this.knobDialsData,
      this.allBurnersData,
      this.ovenSwitchData,
      this.planchaData,
      this.frontGrillData,
      this.flameGrillData,
      this.inductionRingsData,
      this.knobBurnerDialData,
      this.classiqueKnobData,
      this.moderneKnobData,
      this.moderneTrimData,
      this.multiCookerData,
      this.burnerbaseData,
      this.traditionalPlateData,
      this.rangeData,
      // Texture images
      this.backTexture,
      this.blackTexture,
      this.brassCapTexture,
      this.cherryRedTexture,
      this.nickelTexture,
      this.ssTexture,
    ] = await Promise.all([
      // 3D files
      this.#gltfLoader.loadAsync('/models/2-burner-grate.glb'),
      this.#gltfLoader.loadAsync('/models/18k-burner-grates.glb'),
      this.#gltfLoader.loadAsync('/models/All_Dials.glb'),
      this.#gltfLoader.loadAsync('/models/Burners.glb'),
      this.#gltfLoader.loadAsync('/models/Dial_Light_TS.glb'),
      this.#gltfLoader.loadAsync('/models/Electric_Plancha.glb'),
      this.#gltfLoader.loadAsync('/models/Front_Exhaust.glb'),
      this.#gltfLoader.loadAsync('/models/Gas_Grill.glb'),
      this.#gltfLoader.loadAsync('/models/Induction_Rings.glb'),
      this.#gltfLoader.loadAsync('/models/knob-dial-markings.glb'),
      this.#gltfLoader.loadAsync('/models/knob_4.glb'),
      this.#gltfLoader.loadAsync('/models/knob_5a.glb'),
      this.#gltfLoader.loadAsync('/models/M_Trim.glb'),
      this.#gltfLoader.loadAsync('/models/Multi_Cooker.glb'),
      this.#gltfLoader.loadAsync('/models/rangetop-deep.glb'),
      this.#gltfLoader.loadAsync('/models/Simmer_Plate.glb'),
      this.#gltfLoader.loadAsync('/models/sully-2200-min.glb'),
      // Image files
      this.#textureLoader.loadAsync(backImage),
      this.#textureLoader.loadAsync(blackTextureImage),
      this.#textureLoader.loadAsync(brassCapImage),
      this.#textureLoader.loadAsync(cherryRedImage),
      this.#textureLoader.loadAsync(nickelTextureImage),
      this.#textureLoader.loadAsync(ssTextureImage),
    ]);

    this.#setTextureWrapping();
  }

  async loadBackspash() {
    this.backsplashData = await this.#gltfLoader.loadAsync(
      '/models/BackSplash_2200.glb'
    );
  }

  async loadHood() {
    this.hoodData = await this.#gltfLoader.loadAsync('/models/Hood.glb');
  }

  #setTextureWrapping() {
    this.backTexture.repeat.set(1, 1, 1);
    this.backTexture.wrapS = RepeatWrapping;
    this.backTexture.wrapT = RepeatWrapping;

    this.blackTexture.repeat.set(2, 2);
    this.blackTexture.wrapS = RepeatWrapping;
    this.blackTexture.wrapT = RepeatWrapping;

    this.brassCapTexture.repeat.set(1, 1);
    this.brassCapTexture.wrapS = RepeatWrapping;
    this.brassCapTexture.wrapT = RepeatWrapping;

    this.cherryRedTexture.repeat.set(1, 1);
    this.cherryRedTexture.wrapS = RepeatWrapping;
    this.cherryRedTexture.wrapT = RepeatWrapping;

    this.ssTexture.repeat.set(1, 1);
    this.ssTexture.wrapS = RepeatWrapping;
    this.ssTexture.wrapT = RepeatWrapping;
  }
}

export { AssetLoader };
