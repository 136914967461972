import { Group } from 'three';

class Backsplash2200 {
  backsplash = new Group();

  #assets;
  #materials;
  #supportBar;
  #zHook;

  constructor(assets, materials) {
    this.backsplash.name = 'backsplash-2200';
    this.backsplash.position.z = -0.015;
    this.#assets = assets;
    this.#materials = materials;
    this.loadModels();
    this.applyTextures();
    this.adjustZHookPosition();
    this.addPotRailSupports();
    this.addZHook();
  }

  loadModels() {
    this.#assets.backsplashData.scene.children.forEach((child) => {
      this.backsplash.add(child.clone());
    });
  }

  applyTextures() {
    const backsplash = this.backsplash.getObjectByName('BackSplash_2200');
    const potRailBar = this.backsplash.getObjectByName(
      'BackSplash_pot_rail_bar_2200'
    );
    this.#zHook = this.backsplash.getObjectByName('BackSplash_Z_Hook');
    this.#supportBar = this.backsplash.getObjectByName(
      'BackSplash_support_bar_2200'
    );

    this.#materials.applyStainlessSteelTexture(backsplash, this.#supportBar);
    this.#materials.applyGalvanizedSteelTexture(potRailBar, this.#zHook);
  }

  adjustZHookPosition() {
    this.#zHook.position.z += 0.015;
  }

  addPotRailSupports() {
    const leftSupportBar = this.#supportBar.clone();
    leftSupportBar.position.x += -1.035;
    this.backsplash.add(leftSupportBar);

    const rightSupportBar = this.#supportBar.clone();
    rightSupportBar.position.x += 1.035;
    this.backsplash.add(rightSupportBar);
  }

  addZHook() {
    const leftZHook = this.#zHook.clone();
    leftZHook.position.x += -2.06;
    this.backsplash.add(leftZHook);
  }
}

export { Backsplash2200 };
