import { EquirectangularReflectionMapping } from 'three';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import hdrFile from '/assets/envmap/MR_INT-001_NaturalStudio_NAD.hdr?url';

function loadEnvMap(scene) {
  new RGBELoader().load(hdrFile, function (texture) {
    texture.mapping = EquirectangularReflectionMapping;
    scene.environment = texture;
  });
}

export { loadEnvMap };
