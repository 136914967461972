import { MeshStandardMaterial } from 'three';

class Metals {
  // Trims
  brassTrim;
  brushedSSTrim;
  chromeTrim;
  nickelTrim;

  // Other Metals
  brassCap;
  castAluminum;
  galvanizedSteel;
  stainlessSteel;

  // Logo
  logoBorder;
  logoPlate;

  // Enamel Cast Iron
  enamelCastIron;

  #assets;

  constructor(assets) {
    this.#assets = assets;
    this.createMetals();
  }

  createMetals() {
    /******************   Trims   ******************/

    this.brassTrim = new MeshStandardMaterial({
      color: 0xffeda0,
      metalness: 1,
      roughness: 0,
    });

    this.brushedSSTrim = new MeshStandardMaterial({
      color: 0xdbe2e9,
      metalness: 0.91,
      roughness: 0.1,
    });

    this.chromeTrim = new MeshStandardMaterial({
      map: this.#assets.ssTexture,
      metalness: 1,
      roughness: 0.001,
    });

    this.nickelTrim = new MeshStandardMaterial({
      map: this.#assets.nickelTexture,
      metalness: 1,
      roughness: 0.001,
    });

    /**************   Other Metals   ***************/

    this.brassCap = new MeshStandardMaterial({
      map: this.#assets.brassCapTexture,
      metalness: 1,
      roughness: 0,
    });

    this.castAluminum = new MeshStandardMaterial({
      color: 0x808080,
      metalness: 0.5,
      roughness: 0.7,
    });

    this.galvanizedSteel = new MeshStandardMaterial({
      map: this.#assets.backTexture,
      metalness: 1,
      roughness: 0.1,
    });

    this.stainlessSteel = new MeshStandardMaterial({
      map: this.#assets.ssTexture,
      metalness: 1,
      roughness: 0.1,
    });

    /******************   Logo   *******************/

    this.logoBorder = new MeshStandardMaterial({
      color: 0xe5cd9e,
      metalness: 0.9,
      roughness: 0.1,
    });

    this.logoPlate = new MeshStandardMaterial({
      color: 0xffffff,
      metalness: 0.7,
      roughness: 0.1,
    });

    /*************  Enamel Cast Iron  **************/

    this.enamelCastIron = new MeshStandardMaterial({
      map: this.#assets.blackTexture,
      color: 0x5c5b5b,
      metalness: 0.5,
      roughness: 0.4,
    });
  }
}

export { Metals };
