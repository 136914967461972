import { Group } from 'three';

class Hood2400 {
  hood = new Group();
  #mavInsert = new Group();
  #vahInsert = new Group();

  #assets;
  #materials;

  // The selected range color
  #bodyColor = 'burgundyRed';

  // Whether the duct cover finish is 'stainlessSteel' or 'color'
  #ductCoverFinish = 'stainlessSteel';
  #ductCoverBaseHeight;

  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.hood.name = 'hood-2400';

    this.#loadModels();
    this.#applyTextures();

    this.#addMAVBaffles();
    this.#addMAVLights();
    this.#addMAVKnob();

    this.#addVAHLights();
    this.#addVAHKnob();

    this.#setDuctCoverBaseHeight();
  }

  changeInsert(insert) {
    if (insert === 'modern-aire') {
      this.hood.remove(this.#vahInsert);
      this.hood.add(this.#mavInsert);
    } else if (insert === 'vent-a-hood') {
      this.hood.remove(this.#mavInsert);
      this.hood.add(this.#vahInsert);
    }
  }

  changeColor(color) {
    this.#bodyColor = color;
    this.#materials.changeColor(color, [
      this.hood.getObjectByName('Hood_Body'),
    ]);

    if (this.#ductCoverFinish === 'color') {
      this.#materials.changeColor(color, [
        this.hood.getObjectByName('Duct_Cover'),
      ]);
    }
  }

  changeDuctCoverFinish(finish) {
    this.#ductCoverFinish = finish;

    if (finish === 'color') {
      this.#materials.changeColor(this.#bodyColor, [
        this.hood.getObjectByName('Duct_Cover'),
      ]);
    } else {
      this.#materials.applyStainlessSteelTexture(
        this.hood.getObjectByName('Duct_Cover')
      );
    }
  }

  changeDuctCoverHeight(height) {
    const ductCover = this.hood.getObjectByName('Duct_Cover');
    ductCover.position.y = this.#ductCoverBaseHeight + (height - 250) / 2000;
  }

  #loadModels() {
    this.#assets.hoodData.scene.children.forEach((child) => {
      if (child.name.toUpperCase().startsWith('MAV')) {
        this.#mavInsert.add(child.clone());
      } else if (child.name.startsWith('VAH')) {
        this.#vahInsert.add(child.clone());
      } else {
        this.hood.add(child.clone());
      }
    });

    this.hood.add(this.#mavInsert);
  }

  #applyTextures() {
    this.#materials.applyStainlessSteelTexture(
      this.hood.getObjectByName('Duct_Cover'),
      this.hood.getObjectByName('Hood_Lower_Body'),
      this.#mavInsert.getObjectByName('Mav_Insert'),
      this.#mavInsert.getObjectByName('MAV_outter_shell'),
      this.#vahInsert.getObjectByName('VAH_Insert'),
      this.#vahInsert.getObjectByName('VAH_outter_shell')
    );

    this.#materials.applyChromeTexture(
      this.#mavInsert.getObjectByName('MAV_Knob_outter_shell'),
      this.#vahInsert.getObjectByName('VAH_Knob_outter_shell')
    );

    this.#materials.applyGalvanizedSteelTexture(
      this.hood.getObjectByName('Hood_Back_Body')
    );

    this.#materials.applyBurgundyRedTexture(
      this.hood.getObjectByName('Hood_Body')
    );

    this.#materials.applyWhiteTexture(
      this.#mavInsert.getObjectByName('MAV_Light_outter_shell'),
      this.#vahInsert.getObjectByName('VAH_Light_outter_shell')
    );

    this.#materials.applyBlackBodyTexture(
      this.hood.getObjectByName('Hood_Black_Body')
    );
  }

  #addMAVBaffles() {
    const mavBaffle = this.#mavInsert.getObjectByName('Mav_Insert');

    // Add left baffle plates
    for (let i = 1; i <= 3; i++) {
      const baffle = mavBaffle.clone();
      baffle.position.x += i * -0.254375;
      this.#mavInsert.add(baffle);
    }

    // Add right baffle plates
    for (let i = 1; i <= 3; i++) {
      const baffle = mavBaffle.clone();
      baffle.position.x += i * 0.254375;
      this.#mavInsert.add(baffle);
    }
  }

  #addMAVLights() {
    const mavLight = this.#mavInsert.getObjectByName('MAV_Light_outter_shell');

    // Add left lights
    for (let i = 1; i <= 2; i++) {
      const light = mavLight.clone();
      light.position.x += i * -0.367;
      this.#mavInsert.add(light);
    }

    // Add right lights
    for (let i = 1; i <= 2; i++) {
      const light = mavLight.clone();
      light.position.x += i * 0.367;
      this.#mavInsert.add(light);
    }
  }

  #addMAVKnob() {
    const mavKnob = this.#mavInsert.getObjectByName('MAV_Knob_outter_shell');
    const rightKnob = mavKnob.clone();
    rightKnob.position.x += 0.24;

    this.#mavInsert.add(rightKnob);
  }

  #addVAHLights() {
    const vahLight = this.#vahInsert.getObjectByName('VAH_Light_outter_shell');

    const leftLight = vahLight.clone();
    leftLight.position.x += -0.35;

    const rightLight = vahLight.clone();
    rightLight.position.x += 0.35;

    this.#vahInsert.add(leftLight, rightLight);
  }

  #addVAHKnob() {
    const vahKnob = this.#vahInsert.getObjectByName('VAH_Knob_outter_shell');
    const rightKnob = vahKnob.clone();
    rightKnob.position.x += 0.24;

    this.#vahInsert.add(rightKnob);
  }

  #setDuctCoverBaseHeight() {
    const ductCover = this.hood.getObjectByName('Duct_Cover');
    this.#ductCoverBaseHeight = ductCover.position.y;
  }
}

export { Hood2400 };
