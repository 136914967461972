import { Sully2200 } from './ranges/sully2200/Sully2200.js';
import { Hood2400 } from './hoods/Hood2400.js';
import { Backsplash2200 } from './backsplashes/Backsplash2200.js';
import { KnobModels } from './ranges/shared/KnobModels.js';
import { RangeTopOptions } from './ranges/shared/RangeTopOptions.js';
import { Materials } from './shared/Materials.js';
import { AssetLoader } from './shared/AssetLoader.js';
import { loadEnvMap } from './shared/loadEnvMap.js';

import { createCamera } from './components/camera.js';
import { createLights } from './components/lights.js';
import { createScene } from './components/scene.js';

import { createControls } from './systems/controls.js';
import { createRenderer } from './systems/renderer.js';
import { HelperGUI } from './systems/HelperGUI.js';
import { Loop } from './systems/Loop.js';
import { Resizer } from './systems/Resizer.js';

class World {
  #hood;

  #assets;
  #materials;

  #camera;
  #loop;
  #renderer;
  #scene;

  #showing = {
    hood: false,
    backsplash: false,
  };

  #gui;

  constructor(container) {
    this.#assets = new AssetLoader();
    this.#camera = createCamera();
    this.#renderer = createRenderer();
    this.#scene = createScene();
    this.#scene.position.y = -0.5;
    this.#loop = new Loop(this.#camera, this.#scene, this.#renderer);
    this.#gui = new HelperGUI();
    // this.#gui.gui.hide();

    container.appendChild(this.#renderer.domElement);
    const controls = createControls(this.#camera, this.#renderer.domElement);

    const { hemisphereLight, leftLight, rightLight } = createLights();

    this.#loop.updatables.push(controls);

    this.#scene.add(hemisphereLight, leftLight, rightLight);

    const resizer = new Resizer(container, this.#camera, this.#renderer);

    // this.#gui.addHemiLight(hemisphereLight);
    // this.#gui.addLeftLight(leftLight);
    // this.#gui.addRightLight(rightLight);
  }

  async init() {
    await this.#assets.init();

    this.#materials = new Materials(this.#assets);

    const rangeTopOptions = new RangeTopOptions(this.#assets, this.#materials);
    const knobModels = new KnobModels(this.#assets, this.#materials);
    const range = new Sully2200(
      this.#assets,
      this.#materials,
      rangeTopOptions,
      knobModels
    );

    range.changeTrim('brass');
    range.changeColor('burgundy-red');
    range.changeRangeTop('classique');

    this.#scene.add(range.range);

    this.initializeEventListeners(range);

    loadEnvMap(this.#scene);

    this.#gui.addRangeTop(range.range);
  }

  initializeEventListeners(range) {
    // Change the line
    document.getElementById('line').addEventListener('change', (e) => {
      const line = e.target.value;
      const trimSelect = document.getElementById('trim');
      const trim = trimSelect.value;

      range.changeLine(line);

      if (line === 'moderne') {
        // Disable the brass and nickel options
        trimSelect.options[0].disabled = true;
        trimSelect.options[3].disabled = true;

        if (!['brushedSS', 'chrome'].includes(trim)) {
          // Select chrome
          trimSelect.options[2].selected = true;
        }
      } else if (line === 'classique') {
        // Enable the brass and nickel options
        trimSelect.options[0].disabled = false;
        trimSelect.options[3].disabled = false;
      }
    });

    // Change the base range top
    document.getElementById('top').addEventListener('change', (e) => {
      range.changeRangeTop(e.target.value);
    });

    // Change the trim
    document.getElementById('trim').addEventListener('change', (e) => {
      range.changeTrim(e.target.value);
    });

    // Change the color
    document.getElementById('color').addEventListener('change', (e) => {
      range.changeColor(e.target.value);
      this.#hood.changeColor(e.target.value);
    });

    // Change the left cupboard
    document.getElementById('left-cupboard').addEventListener('change', (e) => {
      range.changeLeftCupboard(e.target.value);
    });

    // Change the far left range top option
    document
      .getElementById('far-left-option')
      .addEventListener('change', (e) => {
        range.changeSp1(e.target.value);
      });

    // Change the left range top option
    document.getElementById('left-option').addEventListener('change', (e) => {
      range.changeSp2(e.target.value);
    });

    // Change the right range top option
    document.getElementById('right-option').addEventListener('change', (e) => {
      range.changeSp3(e.target.value);
    });

    // Change the far right range top option
    document
      .getElementById('far-right-option')
      .addEventListener('change', (e) => {
        range.changeSp4(e.target.value);
      });

    // Change the right cupboard
    document
      .getElementById('right-cupboard')
      .addEventListener('change', (e) => {
        range.changeRightCupboard(e.target.value);
      });
  }

  async init2() {
    await this.#assets.loadHood();
    this.#hood = new Hood2400(this.#assets, this.#materials);

    await this.#assets.loadBackspash();
    const backsplash = new Backsplash2200(this.#assets, this.#materials);

    // Show/Hide the hood
    const that = this; // TODO: Replace this with something more elegant
    document.getElementById('hood').addEventListener('change', function () {
      if (this.checked) {
        // Add the hood and zoom the camera out to show the range and hood
        that.#scene.add(that.#hood.hood);
        that.#camera.position.set(0, 1, 7);
        that.#scene.position.y = -1.4;
        that.#showing.hood = true;
        that.#showHoodOptions();
      } else if (that.#showing.backsplash) {
        // Remove the hood and zoom the camera in on the range and backsplash
        that.#scene.remove(that.#scene.getObjectByName(that.#hood.hood.name));
        that.#camera.position.set(0, 1, 5);
        that.#scene.position.y = -0.9;
        that.#showing.hood = false;
        that.#hideHoodOptions();
      } else {
        // Remove the hood and zoom the camera in on the range
        that.#scene.remove(that.#scene.getObjectByName(that.#hood.hood.name));
        that.#camera.position.set(0, 1.5, 4.5);
        that.#scene.position.y = -0.5;
        that.#showing.hood = false;
        that.#hideHoodOptions();
      }
    });

    // Change the hood insert
    document.getElementById('hood-insert').addEventListener('change', (e) => {
      this.#hood.changeInsert(e.target.value);
    });

    // Change the hood duct cover finish
    document
      .getElementById('duct-cover-finish')
      .addEventListener('change', (e) => {
        this.#hood.changeDuctCoverFinish(e.target.value);
      });

    // Change the hood duct cover height
    document
      .getElementById('duct-cover-height')
      .addEventListener('change', (e) => {
        this.#hood.changeDuctCoverHeight(e.target.value);
      });

    // Show/Hide the backsplash
    document
      .getElementById('backsplash')
      .addEventListener('change', function () {
        if (this.checked) {
          // Add the backsplash
          that.#scene.add(backsplash.backsplash);
          that.#showing.backsplash = true;
          if (!that.#showing.hood) {
            // Zoom the camera out to show the range and backsplash
            that.#camera.position.set(0, 1, 5);
            that.#scene.position.y = -0.9;
          }
        } else {
          // Remove the backsplash
          that.#scene.remove(
            that.#scene.getObjectByName(backsplash.backsplash.name)
          );
          that.#showing.backsplash = false;
          if (!that.#showing.hood) {
            // Zoom the camera in on the range
            that.#camera.position.set(0, 1.5, 4.5);
            that.#scene.position.y = -0.5;
          }
        }
      });
  }

  render() {
    this.#renderer.render(this.#scene, this.#camera);
  }

  start() {
    this.#loop.start();
  }

  stop() {
    this.#loop.stop();
  }

  #showHoodOptions() {
    const hoodInsertDiv = document.getElementById('hood-insert-div');
    hoodInsertDiv.style.display = 'block';

    const ductCoverFinishDiv = document.getElementById('duct-cover-finish-div');
    ductCoverFinishDiv.style.display = 'block';

    const ductCoverHeightDiv = document.getElementById('duct-cover-height-div');
    ductCoverHeightDiv.style.display = 'block';
  }

  #hideHoodOptions() {
    const hoodInsertDiv = document.getElementById('hood-insert-div');
    hoodInsertDiv.style.display = 'none';

    const ductCoverFinishDiv = document.getElementById('duct-cover-finish-div');
    ductCoverFinishDiv.style.display = 'none';

    const ductCoverHeightDiv = document.getElementById('duct-cover-height-div');
    ductCoverHeightDiv.style.display = 'none';
  }
}

export { World };
