import { Group } from 'three';

import { Knobs } from './Knobs.js';

class Sully2200 {
  // All range meshes should be added to (or removed from) this group
  range = new Group();
  #classiqueTrim = new Group();
  #moderneTrim = new Group();

  // 3D model and texture assets
  #assets;

  // Knobs placement
  #knobs;

  #rangeTopOptions;

  #rangeTop = {
    baseBurners: [],
    sp1: [],
    sp2: [],
    sp3: [],
    sp4: [],
  };

  #materials;

  #color = 'burgundyRed';
  #line = 'classique';
  #trim = 'brass';

  constructor(assets, materials, rangeTopOptions, knobModels) {
    this.#assets = assets;
    this.#materials = materials;
    this.#rangeTopOptions = rangeTopOptions;
    this.#classiqueTrim.name = 'classique-trim';
    this.#loadModels();
    this.#applyTextures();
    this.#assembleModerneTrim();
    this.#knobs = new Knobs(knobModels, this.range, this.#materials);

    // Configure optional burners for initial display
    this.changeSp1('SS');
    this.changeSp2('LAG024UR');
    this.changeSp3('SS');
    this.changeSp4('SS');
  }

  changeLine(line) {
    this.#line = line;
    this.#knobs.changeLine(line);
    this.#changeControlPanelAndSide(line);

    if (line === 'moderne') {
      this.range.remove(this.#classiqueTrim);
      this.range.add(this.#moderneTrim);

      if (!['brushedSS', 'chrome'].includes(this.#trim)) {
        this.changeTrim('chrome');
      }
    } else if (line === 'classique') {
      this.range.remove(this.#moderneTrim);
      this.range.add(this.#classiqueTrim);
    }
  }

  changeRangeTop(rangeTop) {
    const activeElements = this.#rangeTop.baseBurners;

    activeElements.forEach((name) => {
      const mesh = this.range.getObjectByName(name);
      this.range.remove(mesh);
    });

    switch (rangeTop) {
      case 'classique': {
        this.#add18KBurner(0, activeElements);
        this.#add5And11KBurners(0, this.#rangeTop.baseBurners);
        this.#knobs.threeFeuxBaseTop();
        break;
      }

      case 'traditional': {
        this.#addTradPlate(0, activeElements);
        this.#add5And11KBurners(0, this.#rangeTop.baseBurners);
        this.#knobs.threeFeuxBaseTop();
        break;
      }

      case '4-feux':
        this.#add11KBurners(-1.04, this.#rangeTop.baseBurners);
        this.#add5And15KBurners(0, this.#rangeTop.baseBurners);
        this.#knobs.fourFeuxBaseTop();
        break;
    }
  }

  changeTrim(trim) {
    this.#trim = trim;
    this.#materials.changeTrim(trim, this.#trimParts());
    this.#knobs.changeTrim(trim);
  }

  changeColor(color) {
    this.#color = color;
    this.#materials.changeColor(color, this.#colorParts());
    this.#knobs.changeKnobDialColor(color);

    if (this.#line !== 'moderne') {
      this.#materials.changeColor(
        color,
        [this.range.getObjectByName('FRONT_KNOB_PLATE')] // control panel
      );
    }
  }

  changeLeftCupboard(type) {
    switch (type) {
      case 'warming':
        this.#knobs.selectWarmingCupboard('far-left');
        break;

      case 'storage':
        this.#knobs.selectStorageCupboard('far-left');
        break;
    }
  }

  changeSp1(option) {
    const activeElements = this.#rangeTop.sp1;

    activeElements.forEach((name) => {
      const option = this.range.getObjectByName(name);
      this.range.remove(option);
    });
    // Clear the elements being tracked
    activeElements.length = 0;

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(-1.795, activeElements);
        this.#knobs.add2Burners('far-left');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(-1.795, activeElements);
        this.#knobs.add2Burners('far-left');
        break;

      case 'LAG010UR': // 1 - 18k burners
        this.#add18KBurner(-0.7, activeElements);
        this.#knobs.add1Burner('far-left');
        break;

      case 'LAG010CK': // Flame Grill
        this.#addFlameGrill(-1.795, activeElements);
        this.#knobs.addFlameGrill('far-left');
        break;

      case 'LAE010TPK': // Electric Plancha
        this.#addPlancha(-1.795, activeElements);
        this.#knobs.addPlancha('far-left');
        break;

      case 'LAG010CF': // Traditional Simmer Plate
        this.#addTradPlate(-0.7, activeElements);
        this.#knobs.add1Burner('far-left');
        break;

      case 'LAE010CVA': // Multi-Cooker
        this.#addMultiCooker(-1.795, activeElements);
        this.#knobs.addMultiCooker('far-left');
        break;

      case 'LAE020I': // 2 Induction Rings
        this.#addInductionRings(-1.795, activeElements);
        this.#knobs.addInductionRings('far-left');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#addSSWorkstation(-1.795, activeElements);
        this.#knobs.addSSWorkstation('far-left');
        break;

      default:
        this.#addSSWorkstation(-1.795, activeElements);
        this.#knobs.addSSWorkstation('far-left');
        break;
    }
  }

  changeSp2(option) {
    const activeElements = this.#rangeTop.sp2;

    activeElements.forEach((name) => {
      const option = this.range.getObjectByName(name);
      this.range.remove(option);
    });
    // Clear the elements being tracked
    activeElements.length = 0;

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(-1.445, activeElements);
        this.#knobs.add2Burners('left');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(-1.445, activeElements);
        this.#knobs.add2Burners('left');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#addSSWorkstation(-1.445, activeElements);
        this.#knobs.addSSWorkstation('left');
        break;

      default:
        this.#addSSWorkstation(-1.445, activeElements);
        this.#knobs.addSSWorkstation('left');
        break;
    }
  }

  changeSp3(option) {
    const activeElements = this.#rangeTop.sp3;

    activeElements.forEach((name) => {
      const option = this.range.getObjectByName(name);
      this.range.remove(option);
    });
    // Clear the elements being tracked
    activeElements.length = 0;

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(-0.403, activeElements);
        this.#knobs.add2Burners('right');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(-0.403, activeElements);
        this.#knobs.add2Burners('right');
        break;

      case 'LAG010UR': // 1 - 18k burners
        this.#add18KBurner(0.69, activeElements);
        this.#knobs.add1Burner('right');
        break;

      case 'LAE010TPK': // Electric Plancha
        this.#addPlancha(-0.403, activeElements);
        this.#knobs.addPlancha('right');
        break;

      case 'LAG010CF': // Traditional Simmer Plate
        this.#addTradPlate(0.69, activeElements);
        this.#knobs.add1Burner('right');
        break;

      case 'LAE020I': // 2 Induction Rings
        this.#addInductionRings(-0.403, activeElements);
        this.#knobs.addInductionRings('right');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#addSSWorkstation(-0.403, activeElements);
        this.#knobs.addSSWorkstation('right');
        break;

      default:
        this.#addSSWorkstation(-0.403, activeElements);
        this.#knobs.addSSWorkstation('right');
        break;
    }
  }

  changeSp4(option) {
    const activeElements = this.#rangeTop.sp4;

    activeElements.forEach((name) => {
      const option = this.range.getObjectByName(name);
      this.range.remove(option);
    });
    // Clear the elements being tracked
    activeElements.length = 0;

    switch (option) {
      case 'LAG020UR': // 2 - 11k burners
        this.#add11KBurners(0, activeElements);
        this.#knobs.add2Burners('far-right');
        break;

      case 'LAG024UR': // 2 - 15k burners
        this.#add15KBurners(0, activeElements);
        this.#knobs.add2Burners('far-right');
        break;

      case 'LAG010UR': // 1 - 18k burners
        this.#add18KBurner(1.094, activeElements);
        this.#knobs.add1Burner('far-right');
        break;

      case 'LAG010CK': // Flame Grill
        this.#addFlameGrill(0, activeElements);
        this.#knobs.addFlameGrill('far-right');
        break;

      case 'LAE010TPK': // Electric Plancha
        this.#addPlancha(0, activeElements);
        this.#knobs.addPlancha('far-right');
        break;

      case 'LAG010CF': // Traditional Simmer Plate
        this.#addTradPlate(1.094, activeElements);
        this.#knobs.add1Burner('far-right');
        break;

      case 'LAE010CVA': // Multi-Cooker
        this.#addMultiCooker(0, activeElements);
        this.#knobs.addMultiCooker('far-right');
        break;

      case 'LAE020I': // 2 Induction Rings
        this.#addInductionRings(0, activeElements);
        this.#knobs.addInductionRings('far-right');
        break;

      case 'SS': // Stainless Steel Worktop
        this.#addSSWorkstation(0, activeElements);
        this.#knobs.addSSWorkstation('far-right');
        break;

      default:
        this.#addSSWorkstation(0, activeElements);
        this.#knobs.addSSWorkstation('far-right');
        break;
    }
  }

  changeRightCupboard(type) {
    switch (type) {
      case 'warming':
        this.#knobs.selectWarmingCupboard('far-right');
        break;

      case 'storage':
        this.#knobs.selectStorageCupboard('far-right');
        break;
    }
  }

  #loadModels() {
    // All meshes from the main Sully 2200 model
    this.#assets.rangeData.scene.children.forEach((child) => {
      if (this.#classiqueMeshNames().includes(child.name)) {
        this.#classiqueTrim.add(child.clone());
      } else {
        this.range.add(child.clone());
      }
    });

    this.#assets.frontGrillData.scene.children.forEach((child) => {
      if (child.name === 'M_FRONT_EXHAUST') {
        this.#moderneTrim.add(child.clone());
      } else {
        this.#classiqueTrim.add(child.clone());
      }
    });

    this.range.add(this.#classiqueTrim);

    // Moderne trim models
    this.#assets.moderneTrimData.scene.children.forEach((child) => {
      this.#moderneTrim.add(child.clone());
    });

    // TODO: Remove this mesh from the range model
    this.range.getObjectByName('C_FRONT_EXHAUST').visible = false;
  }

  #applyTextures() {
    this.#materials.applyStainlessSteelTexture(...this.#stainlessSteelParts());

    this.#materials.applyGalvanizedSteelTexture(
      ...this.#galvanizedSteelParts()
    );
  }

  #assembleModerneTrim() {
    const rightOvenBar = this.#moderneTrim
      .getObjectByName('M_Rods_25x684')
      .clone();
    rightOvenBar.position.x += 0.702;

    const rightCupboardBar = this.#moderneTrim
      .getObjectByName('M_Rods_25x382')
      .clone();
    rightCupboardBar.position.x += 1.802;

    const lCupBarRightSupport = this.#moderneTrim
      .getObjectByName('M_Rod_Support_17x25')
      .clone();
    lCupBarRightSupport.position.x += 0.24;

    const lOvenBarLeftSupport = lCupBarRightSupport.clone();
    lOvenBarLeftSupport.position.x += 0.16;

    const lOvenBarRightSupport = lOvenBarLeftSupport.clone();
    lOvenBarRightSupport.position.x += 0.53;

    const rOvenBarLeftSupport = lOvenBarRightSupport.clone();
    rOvenBarLeftSupport.position.x += 0.18;

    const rOvenBarRightSupport = rOvenBarLeftSupport.clone();
    rOvenBarRightSupport.position.x += 0.53;

    const rCupBarLeftSupport = rOvenBarRightSupport.clone();
    rCupBarLeftSupport.position.x += 0.16;

    const rCupBarRightSupport = rCupBarLeftSupport.clone();
    rCupBarRightSupport.position.x += 0.24;

    const rightDrawerLip = this.#moderneTrim
      .getObjectByName('M_Lip_684')
      .clone();
    rightDrawerLip.position.x += 0.702;

    this.#moderneTrim.add(
      rightOvenBar,
      rightCupboardBar,
      lCupBarRightSupport,
      lOvenBarLeftSupport,
      lOvenBarRightSupport,
      rOvenBarLeftSupport,
      rOvenBarRightSupport,
      rCupBarLeftSupport,
      rCupBarRightSupport,
      rightDrawerLip
    );
  }

  #add5And11KBurners(xPos, activeElements) {
    const fiveAnd11KBurners = this.#rangeTopOptions.fiveAnd11KBurners.clone();
    fiveAnd11KBurners.position.x += xPos;
    fiveAnd11KBurners.name = '5k-and-11k-burners' + xPos;

    activeElements.push(fiveAnd11KBurners.name);

    this.range.add(fiveAnd11KBurners);
  }

  #add5And15KBurners(xPos, activeElements) {
    const fiveAnd15KBurners = this.#rangeTopOptions.fiveAnd15KBurners.clone();
    fiveAnd15KBurners.position.x += xPos;
    fiveAnd15KBurners.name = '5k-and-15k-burners' + xPos;

    activeElements.push(fiveAnd15KBurners.name);

    this.range.add(fiveAnd15KBurners);
  }

  #add11KBurners(xPos, activeElements, width = 'wide') {
    const elevenKBurners = this.#rangeTopOptions.elevenKBurners.clone();
    elevenKBurners.position.x += xPos + 0.74;
    elevenKBurners.name = '11k-burners' + xPos;

    let twoBurnersBase;
    if (width === 'wide') {
      twoBurnersBase = this.#rangeTopOptions.twoBurnersBaseWide.clone();
    } else {
      twoBurnersBase = this.#rangeTopOptions.twoBurnersBaseWide.clone();
    }
    twoBurnersBase.position.x += xPos;
    twoBurnersBase.name = '11k-burners-base' + xPos;

    activeElements.push(elevenKBurners.name, twoBurnersBase.name);

    this.range.add(elevenKBurners, twoBurnersBase);
  }

  #add15KBurners(xPos, activeElements, width = 'wide') {
    const fifteenKBurners = this.#rangeTopOptions.fifteenKBurners.clone();
    fifteenKBurners.position.x += xPos + 0.74;
    fifteenKBurners.name = '15k-burners' + xPos;

    let twoBurnersBase;
    if (width === 'wide') {
      twoBurnersBase = this.#rangeTopOptions.twoBurnersBaseWide.clone();
    } else {
      twoBurnersBase = this.#rangeTopOptions.twoBurnersBaseWide.clone();
    }
    twoBurnersBase.position.x += xPos;
    twoBurnersBase.name = '15k-burners-base' + xPos;

    activeElements.push(fifteenKBurners.name, twoBurnersBase.name);

    this.range.add(fifteenKBurners, twoBurnersBase);
  }

  #add18KBurner(xPos, activeElements) {
    const eighteenKBurner = this.#rangeTopOptions.eighteenKBurner.clone();
    eighteenKBurner.position.x += xPos;
    eighteenKBurner.name = '18k-burner' + xPos;

    activeElements.push(eighteenKBurner.name);

    this.range.add(eighteenKBurner);
  }

  #addTradPlate(xPos, activeElements) {
    const tradPlateGroup = this.#rangeTopOptions.tradPlateGroup.clone();
    tradPlateGroup.position.x += xPos;
    tradPlateGroup.name = 'traditional-plate' + xPos;

    activeElements.push(tradPlateGroup.name);

    this.range.add(tradPlateGroup);
  }

  #addMultiCooker(xPos, activeElements) {
    const multiCooker = this.#rangeTopOptions.multiCooker.clone();
    multiCooker.position.x += xPos;
    multiCooker.name = 'multi-cooker' + xPos;

    activeElements.push(multiCooker.name);

    this.range.add(multiCooker);
  }

  #addPlancha(xPos, activeElements) {
    const plancha = this.#rangeTopOptions.plancha.clone();
    plancha.position.x += xPos;
    plancha.name = 'plancha' + xPos;

    activeElements.push(plancha.name);

    this.range.add(plancha);
  }

  #addFlameGrill(xPos, activeElements) {
    const flameGrill = this.#rangeTopOptions.flameGrill.clone();
    flameGrill.position.x += xPos;
    flameGrill.name = 'flame-grill' + xPos;

    activeElements.push(flameGrill.name);

    this.range.add(flameGrill);
  }

  #addInductionRings(xPos, activeElements) {
    const inductionRings = this.#rangeTopOptions.inductionRings.clone();
    inductionRings.position.x += xPos;
    inductionRings.name = 'induction-rings' + xPos;

    activeElements.push(inductionRings.name);

    this.range.add(inductionRings);
  }

  #addSSWorkstation(xPos, activeElements) {
    const ssWorkstation = this.#rangeTopOptions.ssWorkstation.clone();
    ssWorkstation.position.x += xPos;
    ssWorkstation.name = 'ss-workstation' + xPos;

    activeElements.push(ssWorkstation.name);

    this.range.add(ssWorkstation);
  }

  #changeControlPanelAndSide(line) {
    if (line === 'moderne') {
      this.#materials.applyStainlessSteelTexture(
        this.range.getObjectByName('FRONT_KNOB_PLATE'), // control panel
        this.range.getObjectByName('MAIN_BODY') // main body
      );
    } else if (line === 'classique') {
      this.#materials.changeColor(this.#color, [
        this.range.getObjectByName('FRONT_KNOB_PLATE'), // control panel
        this.range.getObjectByName('MAIN_BODY'), // main body
      ]);
    }
  }

  #trimParts() {
    return [
      this.#classiqueTrim.getObjectByName('C_TRIM002'), // far right towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM006'), // right cupboard right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM008'), // right cupboard left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM016'), // right oven right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM009'), // left oven right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM011'), // left cupboard right bar support
      this.#classiqueTrim.getObjectByName('C_TRIM013'), // right oven left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM010'), // left oven left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM012'), // left cupboard left bar support
      this.#classiqueTrim.getObjectByName('C_TRIM001'), // right drawer right pull
      this.#classiqueTrim.getObjectByName('C_TRIM014'), // right drawer left pull
      this.#classiqueTrim.getObjectByName('C_TRIM015'), // left drawer right pull
      this.#classiqueTrim.getObjectByName('C_TRIM'), // left drawer left pull
      this.#classiqueTrim.getObjectByName('C_TRIM007'), // far left towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM003'), // right towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM004'), // center towel bar support
      this.#classiqueTrim.getObjectByName('C_TRIM005'), // left towel bar support
    ];
  }

  #colorParts() {
    return [
      this.range.getObjectByName('MAIN_BODY'), // main body
      this.range.getObjectByName('O_DOOR'), // right oven
      this.range.getObjectByName('O_DOOR001'), // right drawer
      this.range.getObjectByName('O_DOOR002'), // left cupboard
      this.range.getObjectByName('O_DOOR003'), // left oven
      this.range.getObjectByName('O_DOOR004'), // left drawer
      this.range.getObjectByName('O_DOOR005'), // right cupboard
    ];
  }

  #stainlessSteelParts() {
    return [
      this.range.getObjectByName('TOP_BASE_WD'), // top base Wd
      this.range.getObjectByName('Vented_Raised_Wall_Spacer'), // wall spacer
      this.range.getObjectByName('LO2_CHEMINEE'), // left cupboard vent
      this.range.getObjectByName('LO_CHEMINEE'), // left oven vent
      this.range.getObjectByName('RO2_CHEMINEE'), // right cupboard vent
      this.range.getObjectByName('RO_CHEMINEE'), // right oven vent
      this.range.getObjectByName('BOTTOM_PLATE'), // toe kick
      this.range.getObjectByName('BOTTOM_PLATE001'), // range base
      this.range.getObjectByName('C_FRONT_EXHAUST'), // front grill

      this.#classiqueTrim.getObjectByName('C_ROD002'), // right cupboard bar
      this.#classiqueTrim.getObjectByName('C_ROD'), // left cupboard bar
      this.#classiqueTrim.getObjectByName('C_ROD001'), // towel bar
      this.#classiqueTrim.getObjectByName('C_ROD003'), // right oven bar
      this.#classiqueTrim.getObjectByName('C_ROD004'), // left oven bar
      this.#classiqueTrim.getObjectByName('C_FRONT_EXHAUST'), // front grill

      this.#moderneTrim.getObjectByName('M_Lip_684'), // drawer bottom lip
      this.#moderneTrim.getObjectByName('M_Rods_25x684'), // left oven bar
      this.#moderneTrim.getObjectByName('M_Rods_25x382'), // left cupboard bar
      this.#moderneTrim.getObjectByName('M_Rod_Support_17x25'), // bar support
      this.#moderneTrim.getObjectByName('M_FRONT_EXHAUST'), // moderne "grill"
    ];
  }

  #galvanizedSteelParts() {
    return [
      this.range.getObjectByName('BODY_BACK'), // electric oven back
      this.range.getObjectByName('BODY_BACK001'), // body back
      this.range.getObjectByName('BODY_BACK002'), // gas oven back
      this.range.getObjectByName('BODY_BACK004'), // left cupboard back top
      this.range.getObjectByName('BODY_BACK005'), // right cupboard back bottom
      this.range.getObjectByName('BODY_BACK006'), // left cupboard back bottom
      this.range.getObjectByName('BODY_BACK007'), // right cupboard back top
      this.range.getObjectByName('BODY_BACK008'), // gas oven back electrical
      this.range.getObjectByName('C_H_P_L'), // left back bracket
      this.range.getObjectByName('C_H_P_R'), // right back bracket
    ];
  }

  #classiqueMeshNames() {
    return [
      'C_TRIM002',
      'C_TRIM006',
      'C_TRIM008',
      'C_TRIM016',
      'C_TRIM009',
      'C_TRIM011',
      'C_TRIM013',
      'C_TRIM010',
      'C_TRIM012',
      'C_TRIM001',
      'C_TRIM014',
      'C_TRIM015',
      'C_TRIM',
      'C_TRIM007',
      'C_TRIM003',
      'C_TRIM004',
      'C_TRIM005',
      'C_ROD002',
      'C_ROD',
      'C_ROD001',
      'C_ROD003',
      'C_ROD004',
    ];
  }
}

export { Sully2200 };
