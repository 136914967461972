import { Group } from 'three';

class KnobModels {
  classiqueKnob = new Group();
  moderneKnob = new Group();

  convectionOvenDial = new Group();
  convectionOvenSwitch = new Group();
  gasOvenDial = new Group();
  inductionDial = new Group();
  knobBurnerDial = new Group();
  planchaDial = new Group();
  warmingCupboardDial = new Group();

  #assets;
  #materials;

  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.#loadModels();
    this.#createIndicatorLights();
    this.#applyMaterials();
    console.log(this.convectionOvenSwitch);
  }

  #loadModels() {
    // Classique Knob
    this.#assets.classiqueKnobData.scene.children.forEach((child) => {
      this.classiqueKnob.add(child.clone());
    });

    // Moderne Knob
    this.#assets.moderneKnobData.scene.children.forEach((child) => {
      this.moderneKnob.add(child.clone());
    });

    // Knob Dials
    this.#assets.knobDialsData.scene.children.forEach((child) => {
      switch (child.name) {
        case 'Dial_All_K': // Gas Burner Dial
          this.knobBurnerDial.add(child.clone());
          break;
        case 'Dial_HC': // Gas Burner Dial
          this.warmingCupboardDial.add(child.clone());
          break;
        case 'Dial_EO': // Electric Oven Dial
          this.convectionOvenDial.add(child.clone());
          break;
        case 'Dial_GO': // Gas Oven Dial
          this.gasOvenDial.add(child.clone());
          break;
        case 'Dial_EP': // Electric Plancha Dial
          this.planchaDial.add(child.clone());
          break;
        case 'Dial_2IN': // Induction Ring Dial
          this.inductionDial.add(child.clone());
          break;
      }
    });

    // Oven Switch
    this.#assets.ovenSwitchData.scene.children.forEach((child) => {
      switch (child.name) {
        case 'TS_UP': // Static Oven Icon
        case 'TS_DOWN': // Convection Oven Icon
          this.convectionOvenDial.add(child.clone());
          break;
        case 'TS': // Toggle Switch
          this.convectionOvenSwitch.add(child.clone());
          break;
        case 'TS_Bezels': // Toggle Switch Bezel
          this.convectionOvenSwitch.add(child.clone());
          break;
        case 'Dial_Light': // Indicator Light
          const amberLight = child.clone();
          amberLight.name = 'amber-light';
          this.convectionOvenSwitch.add(amberLight);
          break;
        case 'Dial_Light_Bezels': // Indicator Light Bezel
          this.convectionOvenSwitch.add(child.clone());
          break;
      }
    });
  }

  #createIndicatorLights() {
    const amberLight = this.convectionOvenSwitch.getObjectByName('amber-light');
    const redLight = amberLight.clone();
    redLight.name = 'red-light';
    redLight.position.y += -0.02;

    const bezel = this.convectionOvenSwitch
      .getObjectByName('Dial_Light_Bezels')
      .clone();
    bezel.name = 'red-light-bezel';
    bezel.position.y += -0.02;

    this.convectionOvenSwitch.add(redLight, bezel);
  }

  #applyMaterials() {
    this.#materials.applyBrassTexture(
      this.classiqueKnob.getObjectByName('C_LO2_knob_3'),
      this.convectionOvenSwitch.getObjectByName('Dial_Light_Bezels'),
      this.convectionOvenSwitch.getObjectByName('red-light-bezel')
    );

    this.#materials.applyChromeTexture(
      this.moderneKnob.getObjectByName('M_LO2_knob_3'),
      this.convectionOvenSwitch.getObjectByName('TS'),
      this.convectionOvenSwitch.getObjectByName('TS_Bezels')
    );

    this.#materials.applyBeigeDialTexture(this.knobBurnerDial);

    this.#materials.applyAmberLightTexture(
      this.convectionOvenSwitch.getObjectByName('amber-light')
    );

    this.#materials.applyRedLightTexture(
      this.convectionOvenSwitch.getObjectByName('red-light')
    );
  }
}

export { KnobModels };
